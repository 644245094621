@import "src/styles/helpers";

.mentors {
  padding-top: 104px;
  padding-bottom: 104px;

  @include media(desktopAll) {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

.subtitle {
  margin-top: var(--margin-md);
}

.content {
  margin-top: var(--margin-xl);

  @include media(mobile) {
    display: flex;
    align-items: stretch;
    gap: var(--margin-xs);
    @include hideScrollbar;
    overflow-x: auto;
    overflow-y: hidden;
    margin-right: calc(var(--container-padding-mobile) * -1);
    padding-right: var(--container-padding-mobile);
    margin-left: calc(var(--container-padding-mobile) * -1);
    padding-left: var(--container-padding-mobile);
  }

  @include media(tablet) {
    display: grid;
    gap: var(--margin-xs);
  }

  @include media(desktopAll) {
    display: grid;
    gap: var(--margin-xs);
  }
}

.mentor {
  width: 327px;
  flex-shrink: 0;
  @include setProperty(background, linear-gradient(90deg, rgba(246, 255, 254, 0.00) -33.37%, rgba(155, 188, 183, 0.04) 35.32%, rgba(246, 255, 254, 0.00) 118.57%), linear-gradient(90deg, rgba(246, 255, 254, 0.00) -33.37%, rgba(155, 188, 183, 0.11) 35.32%, rgba(246, 255, 254, 0.00) 118.57%));
  text-align: center;
  padding: var(--margin-md) var(--margin-xs);

  @include media(tablet) {
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr;
    padding: 0 24px 0 16px;
    text-align: left;
  }

  @include media(portrait) {
    gap: var(--margin-lg);
  }

  @include media(landscape) {
    gap: var(--margin-xxl);
    padding-right: 48px;
  }

  @include media(desktopAll) {
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: var(--margin-xxl);
    text-align: left;
    padding: 0 48px 0 16px;
  }

  &__photo {
    width: 210px;
    height: 230px;
    position: relative;
    margin: 0 auto var(--margin-md);

    @include media(tablet) {
      margin-bottom: 0;
    }

    @include media(desktopAll) {
      margin-bottom: 0;
    }

    img {
      object-fit: contain;

      @include media(tablet) {
        object-fit: cover;
      }

      @include media(desktopAll) {
        object-fit: cover;
      }
    }
  }

  &__content {
    @include media(tablet) {
      padding-top: var(--margin-sm);
      padding-bottom: var(--margin-sm);
    }
  }

  &__name {
    font-family: 'KharkivTone', sans-serif;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: var(--margin-sm);
  }

  &__experience {
    font-weight: bold;
    font-size: 14px;
    line-height: 2;

    @include media(desktopAll) {
      font-size: 16px;
    }
  }

  &__description {

  }
}
