@import 'src/styles/helpers';

.slide {
  padding: 0 var(--margin-lg);
  height: 100%;

  @include media(portrait) {
    padding: 0 80px;
  }

  @include media(landscape) {
    padding: 0 120px;

    &__content {
      max-width: 596px;
      margin: 0 auto;
    }
  }

  @include media(desktopAll) {

    &__content {
      max-width: 596px;
      margin: 0 auto;
    }
  }

  @include media(desktopSmall) {
    padding: 0 120px;
  }

  @include media(desktop) {
    padding: 0 200px;
  }

  @include media(desktopBig) {
    padding: 0 320px;
  }

  @include media(desktopHuge) {
    padding: 0 320px;
  }
}

.text {
  text-align: center;

  @include media(landscape) {
    text-align: left;
  }

  @include media(desktopAll) {
    text-align: left;
  }
}

.author {
  margin-bottom: var(--margin-xl);
  text-align: center;

  @include media(landscape) {
    display: grid;
    align-items: center;
    gap: 24px;
    grid-template-columns: auto 1fr auto;
  }

  @include media(desktopAll) {
    display: grid;
    align-items: center;
    gap: 24px;
    grid-template-columns: auto 1fr auto;
  }

  &__avatar {
    @include imgCover;
    width: 74px;
    height: 74px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto var(--margin-sm);
    position: relative;

    @include media(landscape) {
      margin-bottom: 0;
      width: 100px;
      height: 100px;
    }

    @include media(desktopAll) {
      margin-bottom: 0;
      width: 100px;
      height: 100px;
    }
  }

  &__info {

    @include media(landscape) {
      text-align: left;
    }

    @include media(desktopAll) {
      text-align: left;
    }
  }

  &__name {
    font-size: 16px;
    line-height: 24px;
    font-family: 'KharkivTone', sans-serif;
    margin-bottom: 4px;

    @include media(desktopAll) {
      font-size: 20px;
      line-height: 28px;
    }

    @include media(desktopSmall) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__description {
    font-size: 12px;
    line-height: 28px;
    letter-spacing: 0.18px;
  }
}

