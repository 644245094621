@import "src/styles/helpers";

.contactModal {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh; // fallback
  height: calc(var(--vh, 1vh) * 100);
  @include setProperty(background, var(--dark-bg), var(--light-bg));
  overflow-y: auto;
  @include hideScrollbar;
}

.closeButton {
  position: fixed;
  z-index: 3;
  right: var(--margin-md);
  top: var(--margin-md);
  width: 40px;
  height: 24px;
  @include setProperty(color, var(--secondary-200), var(--secondary-200));

  @include media(desktopAll) {
    right: 52px;
    top: 32px;
  }

  &:hover {
    @include setProperty(color, var(--secondary-50), var(--secondary-500));
  }

  svg {
    width: 100%;
    height: 100%;
  }
}
