@import '../../../styles/helpers';

.about {
  position: relative;
  transition: none;
  padding-top: 80px;
  display: grid;
  overflow: hidden;

  @include media(mobile) {
    padding-top: 0;
    gap: 56px;
  }

  &__content {
    @include setProperty(background, var(--dark-bg), var(--light-bg));
    position: relative;
    padding-top: 140px;
    padding-bottom: 80px;

    &_top {
      padding-top: 0;
    }

    @include media(mobile) {
      padding-top: 0;
    }
  }

  &__photo {
    width: 100%;
    position: relative;
    height: 680px;

    @include media(desktopHuge) {
      height: 1000px;
    }

    @include media(desktopSmall) {
      height: 640px;
    }

    @include media(landscape) {
      height: 504px;
    }

    @include media(portrait) {
      height: 376px;
    }

    @include media(mobile) {
      height: 156px;
    }

    span {
      width: 100%;
      height: 100%;
    }

    img {
      object-fit: cover;
    }
  }
}

.photos {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 24px;
  padding: 140px 0;

  &__wrap {
    width: 100vw;
  }

  @include media(desktopHuge) {
    gap: 64px;
  }

  @include media(desktopSmall) {
    gap: 8px;
  }

  @include media(tablet) {
    justify-content: flex-start;
  }

  @include media(mobile) {
    justify-content: flex-start;
  }
}

.photo {
  width: 270px;
  height: 316px;
  flex-shrink: 0;
  position: relative;
  @include imgCover;

  @include media(desktopHuge) {
    width: 324px;
    height: 380px;
  }

  @include media(desktopSmall) {
    width: 242px;
    height: 284px;
  }

  @include media(landscape) {
    width: 270px;
    height: 316px;
  }

  @include media(portrait) {
    width: 215px;
    height: 252px;
  }

  @include media(mobile) {
    width: 256px;
    height: 300px;
  }

  &:nth-child(odd) {
    margin-top: 40px;
  }
}

.header {
  display: grid;
  align-items: flex-start;
  justify-content: space-between;
  gap: 140px;
  grid-template-columns: 375px 1fr;

  @include media(landscape) {
    gap: 32px;
  }

  @include media(portrait) {
    grid-template-columns: 1fr;
    gap: 32px;
    text-align: center;
  }

  @include media(mobile) {
    grid-template-columns: 1fr;
    gap: 56px;
  }
}

.title {
  flex-shrink: 0;
  font-size: 28px;
  line-height: 36px;

  @include media(desktopAll) {
    font-size: 40px;
    line-height: 48px;
  }
}

.text {
  margin: 0 auto;
  max-width: 660px;
  font-size: 16px;
  line-height: 2;
  @include setProperty(font-weight, 300, 500);

  @include media(desktopHuge) {
    font-size: 20px;
  }

  @include media(tablet) {
    max-width: 500px;
  }
}

.textContent {
  text-align: left;

  @include media(tablet) {
    text-align: center;
  }

  @include media(desktopAll) {
    text-align: center;
    margin: 0 auto;
  }
}

.slogan {
  max-width: 548px;
  font-size: 20px;
  line-height: 28px;
  font-family: 'KharkivTone', sans-serif;
  margin: 0 auto var(--margin-lg);

  @include media(portrait) {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 26px;
  }

  @include media(landscape) {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 40px;
  }

  @include media(desktopAll) {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 40px;
  }

  @include media(desktopHuge) {
    font-size: 40px;
    line-height: 56px;
    max-width: none;
  }
}

.ctaButton {
  padding-left: 42px;
  padding-right: 42px;
  font-family: 'KharkivTone', sans-serif;
  margin: 40px auto 0;
  font-size: 16px;

  @include media(landscape) {
    margin-top: 40px;
  }

  @include media(desktopSmall) {
    margin-top: 40px;
  }

  @include media(desktop) {
    margin-top: 96px;
  }

  @include media(desktopBig) {
    margin-top: 96px;
  }

  @include media(desktopHuge) {
    margin-top: 104px;
  }
}

.blurBg {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 6px;
  width: 100%;
  @include setProperty(background, var(--primary-500), var(--primary-900));
  filter: blur(54px);
}
