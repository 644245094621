@import 'src/styles/helpers';

.reviews {
  position: relative;
  padding-top: var(--margin-xxl);
  padding-bottom: var(--margin-xxl);
  width: 100%;
  overflow: hidden;

  @include media(tablet) {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  @include media(portrait) {
  }

  @include media(landscape) {
  }

  @include media(desktopAll) {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  @include media(desktopSmall) {
  }

  @include media(desktop) {
  }

  @include media(desktopBig) {
  }

  @include media(desktopHuge) {
  }

}

.title {
  margin-bottom: 92px;
}

.slider {
  width: 100%;
  padding-bottom: calc(56px + 16px);

  @include media(tablet) {
    margin: 48px 0;
  }

  @include media(landscape) {
    padding-bottom: calc(80px + 16px);
  }

  @include media(desktopAll) {
    padding-bottom: calc(80px + 16px);
    margin: 48px 0;
  }
}

.pagination {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 20px;
  bottom: 0 !important;
  padding: 0 !important;
  position: static !important;

  @include media(landscape) {
    justify-content: flex-end !important;
    width: 596px;
    margin: 0 auto !important;
    height: 28px;
  }

  @include media(desktopAll) {
    justify-content: flex-end !important;
    width: 596px;
    margin: 0 auto !important;
    height: 28px;
  }

  &__item {
    @include setProperty(background, url('/media/elps_border_dark.svg') no-repeat center / contain, url('/media/elps_border_light.svg') no-repeat center / contain);
    width: 16px !important;
    height: 16px !important;
    margin: 0 !important;

    &:global {

      &.is-active {
        transform: none !important;
        @include setProperty(background, var(--dark-elipse-gradient), var(--light-elipse-grain));
      }
    }
  }
}

.parallax {
  * {
    pointer-events: none !important;
  }

  &__draw {
    position: absolute;
    left: -10%;
    top: 150px;
    transform: rotate(100deg);

    @include media(portrait) {
      left: 0;
    }

    @include media(landscape) {
      left: -10%;
      top: 100px;
    }

    @include media(desktopSmall) {
      left: 120px;
      top: 70px;
    }

    @include media(desktop) {
      left: 120px;
      top: 70px;
    }

    @include media(desktopBig) {
      left: 20%;
      top: 70px;
    }

    @include media(desktopHuge) {
      left: 20%;
      top: 70px;
    }
  }

  &__noise {
    position: absolute;
    left: -10px;
    top: 220px;

    @include media(portrait) {
      left: 50px;
    }

    @include media(landscape) {
      left: -50px;
      top: 140px;
    }

    @include media(desktopSmall) {
      left: 120px;
      top: 250px;
    }

    @include media(desktop) {
      left: 120px;
      top: 250px;
    }

    @include media(desktopBig) {
      left: 20%;
      top: 250px;
    }

    @include media(desktopHuge) {
      left: 33%;
      top: 40px;
    }
  }

  &__border {
    position: absolute;
    opacity: 0.5;
    right: 10px;
    bottom: 40%;

    @include media(portrait) {
      right: 40%;
    }

    @include media(landscape) {
      left: 120px;
      bottom: 20%;
    }

    @include media(desktopSmall) {
      left: 120px;
      bottom: 20%;
    }

    @include media(desktop) {
      left: 25%;
      bottom: 30%;
    }

    @include media(desktopBig) {
      left: 25%;
      bottom: 30%;
    }

    @include media(desktopHuge) {
      left: 30%;
      bottom: 15%;
    }
  }
}
